import { PoolConfig } from '@trustpad/launchpad';

import { tpadLogoTextImg } from 'assets';
import * as React from 'react';

import { SocialLinks } from '~common/components/SocialLinks';

const social: PoolConfig['social'] = {
  twitter: 'https://twitter.com/TrustPad',
  medium: 'https://medium.com/@TrustPad',
  telegram: 'https://t.me/TrustPad',
};

export function ErrorScreen({
  statusCode,
  error,
}: {
  statusCode?: any;
  error: Error;
}) {
  const errorStr = (error?.message ||
    error?.toString() ||
    error ||
    'No error') as string;
  return (
    <div className="flex justify-center w-screen h-screen">
      <div className="text-center" style={{ paddingTop: '10vh' }}>
        <img src={tpadLogoTextImg.src} className="mb-6 p-6" />
        <div className="space-y-3 mb-6">
          <p>An error has occurred. We are notified.</p>
          <p>Please send us the screenshot of this screen.</p>

          <SocialLinks social={social} size="text-2xl justify-center" />
        </div>

        {statusCode && <div>Status code: {statusCode}</div>}

        <pre className="bg-gray-800 p-3 text-white rounded">{errorStr}</pre>
      </div>
    </div>
  );
}
